<template>
  <div class="bg bg9">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <div class="class--container">
        <div style="margin-top: 66px;margin-bottom: 22px;display: flex;justify-content: center">
          <div class="img_bg" v-show="gameType === 2">
            2024学院之路赛事
          </div>
          <div class="img_bg" v-show="gameType !== 2">
            {{Y}}年度CFAC赛事
          </div>
<!--          <img :src="menu2" alt="">-->
        </div>
        <div class="class--bonus" v-for="(i,k) in currentPageData"
             :class="i.groupName === '特金奖' ? 'tjj' : i.groupName === '金奖' ? 'jj' : i.groupName === '银奖' ? 'yj' : 'tj' "
             :key="k">
          <div class="class--info__text">
<!--            <div v-for="(ii,kk) in i._child" :key="kk">-->
<!--              <span class="fontSezi35" style="font-weight: bold">{{ ii.studentName }}</span>-->
<!--              <span class="fontSezi35">《{{ ii.workName }}》</span>-->
<!--            </div>-->
            <div v-for="(ii,kk) in i._child" :key="kk">
              <div class="class--info__text_name">{{ ii.studentName }}</div>
              <div class="class--info__text_workName">《{{ ii.workName }}》</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import menu2 from "@/assets/image/conference/bt_title3.png"
import menu3 from "@/assets/image/conference/pic_tejin.png"
import service from "@/utils/axios";
import moment from "moment";
export default {
  name: "thirteenPage",
  components: {ClassBut},
  data() {
    return {
      menu2: menu2,
      menu3: menu3,
      courseAllPercent: [],//全部数据
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, //当前页数 ，默认为1
      pageSize: 1, // 每页显示数量
      currentPageData: [], //当前页显示内容
      Y:moment().format('YYYY'),
      gameType:null,
    }
  },
  created() {
    this.getApicourseAllPercent()
  },
  methods: {
    forward() {
      //下一页
      if (this.currentPage === this.totalPage) {
        this.$router.push({
          name: 'groupPhoto',
          query: this.$route.query,
        })
        return
      }
      this.currentPage++;
      console.log()
      this.gameType = this.courseAllPercent[this.currentPage - 1].gameType
      this.setCurrentPageData();
    },

    backOfff() {
      //上一页
      if (this.currentPage === 1) {
        this.$router.back(-1)
        return
      }
      this.currentPage--;
      this.gameType = this.courseAllPercent[this.currentPage - 1].gameType
      this.setCurrentPageData();
    },


    async getApicourseAllPercent() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseGame`, {
        classId: this.$route.query.classId,
        gameType: 1,
      })
      let res2 = await service.post(`/dwart/an_teaching/hbk/v1/search/courseGame`, {
        classId: this.$route.query.classId,
        gameType: 2,
      })
      const order = ["铜奖", "银奖", "金奖"];
      const order1 = ["银奖", "金奖", "特金奖"];
      const sortByOrder = (data, order) => {
        return data.sort((a, b) => order.indexOf(a.groupName) - order.indexOf(b.groupName));
      };
      const sortedData = sortByOrder(res2, order);
      const sortedData1 = sortByOrder(res, order1);
      res2 = sortedData.map(v=>{
        return { ...v,gameType:2 }
      })
      res = sortedData1.concat(res2)
      this.courseAllPercent = res
      if (this.courseAllPercent.length === 0) {
        await this.$router.replace({
          name: 'groupPhoto',
          query: this.$route.query,
        })
      } else {
        res.map(v => {
          if (v.groupName !== '特金奖' && v.groupName !== '金奖' && v.groupName !== '银奖') {
            // this.$router.replace('/reportClass/fourteenPage')
          }
        })
      }
      await this.setCurrentPageData()
    },

    setCurrentPageData() {
      this.totalPage = Math.ceil(this.courseAllPercent.length / this.pageSize);
      this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
      let begin = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      this.currentPageData = this.courseAllPercent.slice(begin, end);
    },

  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 30px 10px 90px 90px;
  box-sizing: border-box;
  .img_bg{
    background: url("~@/assets/image/conference/bt_title3.png") no-repeat;
    background-size: 100% 100%;
    width: 580px;
    height: 246px;
    font-size: 50px;
    color: #FFFFFF;
    text-align: center;
    line-height: 246px;
  }
}

.tjj {
  background-image: url("~@/assets/image/conference/pic_tejin.png");
}

.jj {
  background-image: url("~@/assets/image/conference/pic_jinj.png");
}

.yj {
  background-image: url("~@/assets/image/conference/pic_yin.png");
}
.tj{
  background-image: url("~@/assets/image/conference/tongj.png");
}

.class--bonus {
  background-size: 100% 100%;
  width: 584px;
  height: 424px;
  margin: 0 auto;
  position: relative;

  .class--info__text {
    position: absolute;
    width: 93%;
    height: 78%;
    bottom: 30px;
    box-sizing: border-box;
    text-align: center;
    overflow-y: auto;
    left: 26px;
    >div{
      display: flex;
    }
    .class--info__text_name{
      width: 40%;
      border-bottom: 2px solid #FF6640;
      border-right: 2px solid #FF6640;
      height: 60px;
      text-align: center;
      line-height: 60px;
      color: #2B3654;
      font-size: 36px;
      font-weight: bold;
    }
    .class--info__text_workName{
      width: 60%;
      border-bottom: 2px solid #FF6640;
      height: 60px;
      text-align: center;
      line-height: 60px;
      color: #2B3654;
      font-size: 36px;
    }
    //> div {
    //  margin-bottom: 20px;
    //}
  }
}

.fontSezi35 {
  font-size: 35px;
  color: #2B3654;
}

::-webkit-scrollbar {
  display: none;
}

</style>
